/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*
 *	VARIABLES
 */
@font-face {
  font-family: 'Cooper Hewitt';
  src: url("/fonts/CooperHewitt-SemiboldItalic.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/* rich-content */
.rich-content {
  width: 100%; }

/* rc-block */
.rc-block {
  margin-bottom: 1.5rem; }

.rc-block--callout {
  padding: 1.5rem;
  background-color: #efefef; }

/* rc-cta */
.rc-cta {
  width: 100%;
  max-width: 380px;
  margin-left: auto;
  margin-right: auto; }
  .rc-cta img {
    width: 100%; }

/* article-image-left */
/* article-image-right */
/* rc-two-cols */
/* rc-three-cols */
/* rc-image-list */
.rc-image-list img {
  width: 100%; }

/* rc-image-slider */
.rc-image-slider__slider {
  margin: 0 auto;
  max-width: 100%; }

/* rc-one-image */
.rc-one-image {
  width: 100%; }

.rc-one-image img {
  margin: 0 auto; }

/* rc-two-images */
.rc-two-images img {
  margin: 0 auto; }

/* rc-three-images */
.rc-three-images img {
  margin: 0 auto; }

/* rc-video */
.rc-video .video,
.rc-video-left .video,
.rc-video-right .video {
  position: relative;
  width: 100%;
  max-width: 854px;
  margin: 0 auto; }

.rc-video .thumb,
.rc-video-left .thumb,
.rc-video-right .thumb {
  z-index: 1;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0; }
  .rc-video .thumb img,
  .rc-video-left .thumb img,
  .rc-video-right .thumb img {
    max-width: 100%;
    width: 100%;
    max-height: 100%; }
  .rc-video .thumb::before,
  .rc-video-left .thumb::before,
  .rc-video-right .thumb::before {
    z-index: 1;
    content: '';
    display: block;
    width: 20%;
    padding-bottom: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url("../../img/rc/rc-play.svg");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center center; }

/* rc-issuu */
.rc-issuu {
  width: 100%;
  background-color: #000;
  text-align: center;
  margin-bottom: 1.5rem; }
  .rc-issuu .issuuembed.issuu-isrendered {
    width: 100% !important; }

/* rc-attachments */
.rc-attachments span {
  font-size: 90%; }

/* rc-quote */
/* rc-btn */
/* rc-form */
.rc-form {
  margin: 0 0 1.5rem; }

/* rc-gmap */
.rc-gmap {
  margin-bottom: 1.5rem; }
  .rc-gmap .map {
    width: 100%;
    height: 350px; }

/* rc-sounds */
.rc-sounds {
  padding: 0;
  margin: 0;
  margin-bottom: 1.5rem;
  list-style: none; }
  .rc-sounds li {
    margin: 0 0 10px 0; }
    .rc-sounds li audio {
      max-width: 100%; }

/* rc-tweet */
.rc-tweet iframe {
  margin-left: auto !important;
  margin-right: auto !important; }

.rc-tweet .twitter-tweet {
  width: auto !important;
  max-width: 100%; }

/* rc-insta */
.rc-insta .instagram-media {
  margin-left: auto !important;
  margin-right: auto !important; }

/* rc-vine */
.rc-vine {
  width: 100%;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  text-align: center; }
  .rc-vine iframe {
    margin-left: auto !important;
    margin-right: auto !important; }

/* rc-facebook */
.rc-facebook {
  margin-bottom: 30px;
  text-align: center; }
  .rc-facebook .fb_iframe_widget {
    max-width: 100%; }
    .rc-facebook .fb_iframe_widget span {
      max-width: 100%;
      overflow: hidden; }

/* social share */
.social-share {
  margin: 10px 0; }

.social-share ul:after {
  content: '';
  display: block;
  clear: both; }

.social-share ul {
  margin: 0;
  padding: 0; }

.social-share ul li {
  float: left;
  margin: 0 5px;
  list-style-type: none; }

.social-share ul li a {
  width: 30px;
  height: 30px;
  display: block;
  font-size: 20px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  opacity: 0.6; }

.social-share ul li.facebook a {
  background: url(../img/social-share/facebook.png);
  background-size: 30px 30px; }

.social-share ul li.twitter a {
  background: url(../img/social-share/twitter.png);
  background-size: 30px 30px; }

.social-share ul li.linkedin a {
  background: url(../img/social-share/linkedin.png);
  background-size: 30px 30px; }

.social-share ul li.google-plus a {
  background: url(../img/social-share/googleplus.png);
  background-size: 30px 30px; }

.social-share ul li a:hover {
  opacity: 1; }

@media (min-width: 34em) {
  .rc-cta {
    float: left;
    width: 47%;
    margin-left: 0;
    margin-right: 3%; } }

@media (min-width: 48em) {
  .rc-cta {
    width: 31.33%;
    margin-right: 2%; } }

/*
 *	CONTENT BLOCK OVERRIDES
 */
/*
 *	VARIABLES
 */
@font-face {
  font-family: 'Cooper Hewitt';
  src: url("/fonts/CooperHewitt-SemiboldItalic.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
.rich-content img {
  width: 100%;
  height: auto; }

.rc-quote {
  color: #ffff;
  font-style: italic; }
  .rc-quote .blockquote {
    border-left: none;
    font-size: 1rem; }
  .rc-quote .blockquote::before {
    content: '"';
    position: absolute;
    top: -20px;
    left: 2rem;
    font-size: 5rem;
    font-family: Times, serif; }
  .rc-quote .blockquote::after {
    content: '"';
    position: absolute;
    bottom: 0px;
    right: 2rem;
    font-size: 5rem;
    font-family: Times, serif; }
  .rc-quote footer {
    color: #2C4E46;
    text-transform: uppercase;
    font-weight: 700;
    font-style: normal;
    background-color: transparent; }
  .rc-quote cite {
    font-weight: 100;
    font-style: normal; }

.rc-video,
.rc-video-left,
.rc-video-right {
  /*.thumb::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: rgba(blue, 0.75);
	}*/ }

@charset "UTF-8";
/* --- n t r i g a . a g e n c y ---
web  -> http://www.ntriga.agency
mail -> info@ntriga.agency
------------------------------ */
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite.png"); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url("/front/resources/plugins/fancybox/img/fancybox_loading.gif") center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url("/front/resources/plugins/fancybox/img/blank.gif");
  /* helps IE */
  -webkit-tap-highlight-color: transparent;
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url("/front/resources/plugins/fancybox/img/fancybox_overlay.png"); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_sprite@2x.png");
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/ }
  #fancybox-loading div {
    background-image: url("/front/resources/plugins/fancybox/img/fancybox_loading@2x.gif");
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/ } }

/*
 *	VARIABLES
 */
@font-face {
  font-family: 'Cooper Hewitt';
  src: url("/fonts/CooperHewitt-SemiboldItalic.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

/* Bootstrap breakpoints */
/* fonts */
/* colours */
/*
 *	MIXINS
 */
/*
 *	BOOSTRAP OVERRIDES
 */
/* text */
.h1, h2, h3, h4, h5, h6 {
  font-family: "Cooper Hewitt", Helvetica, Arial, sans-serif; }

.h1, h1 {
  font-size: 5rem;
  color: rgba(34, 146, 88, 0.8);
  letter-spacing: 1px;
  font-weight: 700;
  text-transform: uppercase; }

.h2, h2 {
  font-size: 2rem;
  color: #fff;
  letter-spacing: 1px;
  font-weight: bold;
  text-transform: uppercase;
  font-style: italic; }

.h3, h3 {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 700;
  font-style: italic; }

p, a {
  font-family: "Ubuntu", Helvetica, Arial, sans-serif; }

p {
  letter-spacing: 1px;
  padding-top: 2rem; }

a {
  /* tab focus */ }
  a:focus, a.focus, a:active:focus, a:active.focus, a.active:focus, a.active.focus {
    color: #229258; }

em, .italic {
  font-style: italic; }

.light {
  font-weight: 100; }

/* buttons */
.btn-primary {
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 10px;
  padding: 0.25rem 1rem;
  letter-spacing: 2px;
  margin-top: 3rem;
  display: inline-block;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */ }
  .btn-primary:focus, .btn-primary.focus, .btn-primary:active:focus, .btn-primary:active.focus, .btn-primary.active:focus, .btn-primary.active.focus {
    outline: none;
    color: #229258;
    background-color: white;
    border: 1px solid white; }
  .btn-primary:hover, .btn-primary.hover, .btn-primary:focus {
    outline: none;
    color: #229258;
    background-color: white;
    border: 1px solid white;
    text-decoration: none; }

.btn-secondary {
  color: #2C4E46;
  text-transform: uppercase;
  font-weight: 700;
  background-color: transparent;
  border: 2px solid #2C4E46;
  padding: 1rem 1rem;
  letter-spacing: 2px;
  margin-top: 2rem;
  margin-right: 1rem;
  display: inline-block;
  border-radius: 0;
  /* tab focus */
  /* hover */
  /* active */
  /* disabled */ }
  .btn-secondary:focus, .btn-secondary.focus, .btn-secondary:active:focus, .btn-secondary:active.focus, .btn-secondary.active:focus, .btn-secondary.active.focus {
    outline: none;
    color: white;
    background-color: #2C4E46;
    border: 1px solid #2C4E46;
    text-decoration: none; }
  .btn-secondary:hover, .btn-secondary.hover, .btn-secondary:focus {
    outline: none;
    color: white;
    background-color: #2C4E46;
    border: 1px solid #2C4E46;
    text-decoration: none; }
  .btn-secondary.disabled, .btn-secondary[disabled],
  fieldset[disabled] .btn-secondary {
    outline: none;
    color: #2C4E46;
    background-color: white;
    border: 2px solid #2C4E46;
    text-decoration: none;
    border-radius: 0; }

/* forms */
/* ... */
/* box sizing */
html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

#fancybox-loading,
#fancybox-loading div,
#fancybox-overlay,
#fancybox-wrap,
#fancybox-wrap div {
  box-sizing: content-box; }

/* standard */
html {
  overflow-y: scroll;
  height: 100%; }

body {
  min-height: 100%; }

/*
 *	UTILITIES
 */
.red-bg {
  background-color: #229258;
  color: white; }

.lightest-gray-bg {
  background-color: #fafafa; }

.gray-bg {
  background-color: #efefef; }

.black {
  color: #2C4E46; }

.white {
  color: white; }

.border-x {
  border-left: 1px solid #333;
  border-right: 1px solid #333; }

.close-menu {
  position: absolute;
  top: 1.4rem;
  right: 1.8rem;
  background-color: transparent;
  border: none;
  display: none;
  z-index: 100000; }
  .close-menu img {
    width: 16px;
    height: 16px; }

/*
 *	GENERAL
 */
#logo img {
  width: 150px;
  height: auto; }

.navigation {
  float: right;
  padding-right: 2rem; }

.navbar-toggler {
  color: #229258;
  top: 1rem;
  z-index: 100000; }

.navbar-toggler .icon-bar {
  transition: 300ms ease-in-out;
  background-color: #229258;
  position: relative;
  width: 24px;
  height: 3px;
  display: block; }

.navbar-toggler .icon-bar:nth-child(1) {
  transform: rotate(-45deg);
  top: 12px; }

.navbar-toggler .icon-bar:nth-child(2) {
  transform: rotate(45deg);
  top: 10px; }

.navbar-toggler .icon-bar:nth-child(3) {
  opacity: 0; }

.navbar-toggler.collapsed .icon-bar {
  transform: rotate(0deg);
  top: 0;
  opacity: 1;
  margin-top: 5px; }

.nav-link {
  color: white;
  text-transform: uppercase;
  font-weight: 700; }

.nav-link:hover {
  color: #229258; }

.dropdown-menu {
  position: fixed;
  top: 100px;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 100%;
  padding: 1rem 0;
  margin: 0;
  font-size: 1rem;
  color: #fff;
  text-align: center;
  list-style: none;
  background-color: #2C4E46;
  border: none;
  border-radius: 0; }

.dropdown-item {
  width: auto;
  margin: 0 0.5rem;
  text-transform: uppercase;
  color: white;
  display: inline;
  font-size: 0.8rem;
  letter-spacing: 1px; }

.dropdown-item:hover {
  color: #229258;
  background-color: transparent; }

/*
* HOME
*/
.headerimage--home {
  height: 90vh;
  width: 100%;
  background-image: url("/img/lapptic-campaign.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  top: 0; }
  .headerimage--home .navigation {
    padding-top: 3rem; }
  .headerimage--home h1 {
    padding-top: 40vh; }

.headerimage--default {
  height: 60vh;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  top: 0; }
  .headerimage--default #logo img {
    height: 75px;
    width: auto; }
  .headerimage--default .main-navigation {
    background-color: white; }
  .headerimage--default h1 {
    padding-top: 25vh; }
  .headerimage--default .nav-link {
    color: #2C4E46;
    text-transform: uppercase;
    font-weight: 700; }
  .headerimage--default .nav-link:hover {
    color: #229258; }

/*
* SERVICES
*/
.service-item {
  width: 100%;
  height: 30vh;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 120%;
  display: block;
  text-align: center;
  color: white;
  text-transform: uppercase;
  font-weight: 700;
  font-style: italic;
  font-size: 1.6rem;
  letter-spacing: 1px; }
  .service-item p {
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 100%;
    text-align: center; }
  .service-item.active {
    color: #229258;
    background-size: 130%;
    transition: background-size .5s ease-in-out; }

.service-hover {
  background-color: #2C4E46;
  color: #229258;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-image: url("/img/Embleem_ARTofrunning_Green.svg");
  background-position: center;
  background-size: 100px 45px;
  background-repeat: no-repeat; }
  .service-hover p {
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 100%;
    text-align: center; }

.service-item:hover {
  background-size: 130%;
  transition: background-size .5s ease-in-out; }
  .service-item:hover p {
    display: none; }
  .service-item:hover .service-hover {
    opacity: 0.7;
    transition: opacity .5s ease-in-out; }
    .service-item:hover .service-hover p {
      display: block; }

/*
* CTA
*/
.cta {
  font-size: 1.1rem;
  letter-spacing: 1px;
  font-weight: 100; }
  .cta a {
    font-weight: 400; }

/*
* REFERENTIES
*/
.referenties .row div:nth-child(2) {
  background-color: #efefef; }

.referenties .row div:nth-child(4) {
  background-color: #efefef; }

.referenties .row div:nth-child(5) {
  background-color: #efefef; }

.referenties .row div:nth-child(7) {
  background-color: #efefef; }

.referenties .row div:nth-child(10) {
  background-color: #efefef; }

.referenties .row div:nth-child(12) {
  background-color: #efefef; }

.referenties .row div:nth-child(13) {
  background-color: #efefef; }

.referenties .row div:nth-child(15) {
  background-color: #efefef; }

/*
* FOOTER
*/
footer {
  background-color: #2C4E46;
  color: #ffff;
  line-height: 2rem; }
  footer a {
    color: #ffff; }
  footer a:hover {
    color: white; }
  footer ul, footer li {
    margin-left: 0;
    padding-left: 0; }
  footer li {
    list-style-type: none; }
  footer .footer-title {
    color: #229258;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px; }

.footer-social a {
  color: #229258;
  font-size: 2rem; }

.footer__bottom {
  border-bottom: 1px solid #333;
  border-top: 1px solid #333;
  width: 100%;
  display: block; }

.footer__top .partner {
  margin-right: 3rem; }
  .footer__top .partner img {
    opacity: 0.3; }

.footer__credits {
  font-size: 0.7rem;
  background-color: #222; }
  .footer__credits .madeby {
    float: right; }

/*
 *	IMPORTS
 */
@font-face {
  font-family: 'fontello';
  src: url("/fonts/fontello.eot?80210125");
  src: url("/fonts/fontello.eot?80210125#iefix") format("embedded-opentype"), url("/fonts/fontello.woff2?80210125") format("woff2"), url("/fonts/fontello.woff?80210125") format("woff"), url("/fonts/fontello.ttf?80210125") format("truetype"), url("/fonts/fontello.svg?80210125#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?80210125#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-menu-1:before {
  content: '\e804'; }

/* '' */
.icon-twitter-squared:before {
  content: '\f304'; }

/* '' */
.icon-facebook-squared:before {
  content: '\f308'; }

/* '' */
.icon-linkedin-squared:before {
  content: '\f30c'; }

/* '' */
/*
 *	WEBSHOP STANDAARD CSS
 */
/* 
 *	CART ITEM
 */
.ws-cart-item {
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
  border-top: 1px solid #f0f0f0;
  padding: 1rem 0; }
  .ws-cart-item:last-of-type {
    border-bottom: 1px solid #f0f0f0; }

.ws-cart-item__img {
  -ms-flex: 1 0 17%;
  flex: 1 0 17%;
  max-width: 17%; }

.ws-cart-item__details {
  -ms-flex: 1 0 50%;
  flex: 1 0 50%;
  max-width: 50%;
  padding-left: 1rem; }

.ws-cart-item__actions {
  -ms-flex: 1 0 33%;
  flex: 1 0 33%;
  max-width: 33%;
  padding-left: 1rem; }
  .ws-cart-item__actions .price {
    margin-bottom: 1rem; }
    .ws-cart-item__actions .price::after {
      content: '';
      display: table;
      clear: both; }
  .ws-cart-item__actions .amount {
    float: left; }
    .ws-cart-item__actions .amount input {
      display: inline-block;
      margin-left: .5rem;
      width: 60px; }
  .ws-cart-item__actions .remove {
    clear: both; }

/*
*	CART LIST
*/
.ws-cart-dropdown {
  font-size: .8em;
  padding: 1rem; }
  .ws-cart-dropdown .ws-cart-list {
    max-width: 350px; }
    .ws-cart-dropdown .ws-cart-list .ws-cart-item p {
      margin-bottom: 5px; }
  .ws-cart-dropdown .ws-cart-item__actions .price {
    margin-bottom: 0; }
  .ws-cart-dropdown .ws-cart-item {
    padding: .5rem 0; }
  .ws-cart-dropdown .ws-cart-actions {
    padding: 1rem 0 0 0; }
    .ws-cart-dropdown .ws-cart-actions:after {
      content: '';
      display: block;
      clear: both; }
  .ws-cart-dropdown .ws-cart-total {
    padding: 10px;
    background-color: #F8F8F8; }

.custom-input {
  width: auto;
  display: inline-block;
  max-width: 60px;
  margin-left: .5rem;
  padding: .375rem .75rem .375rem .75rem;
  color: #55595c;
  vertical-align: middle;
  background: #fff; }

/*
 *	CART INFO
 */
.ws-info-box {
  padding: 1rem;
  background-color: #ddd; }
  .ws-info-box ul {
    padding-left: 0; }
  .ws-info-box li {
    list-style: none; }
  .ws-info-box .price {
    float: right; }
  .ws-info-box .table td {
    border-top: none; }
    .ws-info-box .table td:last-child {
      text-align: right; }
  .ws-info-box tfoot {
    border-top: 1px solid rgba(0, 0, 0, 0.1); }
    .ws-info-box tfoot tr:last-child {
      border-top: 1px solid rgba(0, 0, 0, 0.1); }

/* 
 *	CONTINUE
 */
.ws-continue {
  padding: 1rem 1rem .5rem;
  background-color: #ddd; }
  .ws-continue .btn {
    width: 100%; }

.ws-continue__logos {
  display: block;
  margin-bottom: 0;
  padding: 0;
  font-size: 0; }
  .ws-continue__logos li {
    display: inline-block;
    margin-right: .5rem;
    margin-bottom: .5rem; }

/*
 *	PROGRESS
 */
.ws-progress .nav {
  width: 100%;
  display: -ms-flexbox;
  display: flex; }

.ws-progress .nav-item {
  -ms-flex: 1 0 25%;
  flex: 1 0 25%; }

.ws-progress .nav-link {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;
  background-color: #ddd; }
  .ws-progress .nav-link.active {
    font-weight: bold;
    font-style: italic; }

.ws-progress span.nav-link {
  color: rgba(0, 0, 0, 0.5); }

.ws-progress .nav-item:nth-child(2) .nav-link {
  background-color: #e5e5e5; }

.ws-progress .nav-item:nth-child(3) .nav-link {
  background-color: #ececec; }

.ws-progress .nav-item:nth-child(4) .nav-link {
  background-color: #f4f4f4; }

/*
 *	PAYMENT OPTIONS
 */
.ws-payment-options {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 1; }
  .ws-payment-options li {
    display: inline-block;
    width: 50px;
    margin: 0 3px 5px 0; }
  .ws-payment-options img {
    max-width: 100%; }

.ws-pay-icon {
  width: 35px;
  vertical-align: middle; }

@media (max-width: 543px) {
  .ws-progress .nav {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
  .ws-progress .nav-item {
    -ms-flex: 1 0 100%;
    flex: 1 0 100%; }
  .ws-progress .nav-link {
    padding: .5rem; } }

/*
 *	Tijdelijke hacks en bugfixes
 */
/*
 *	CUSTOM RESPONSIVE CODE
 */
@media (max-width: 1199px) {
  .nav-link {
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 0.8rem; } }

@media (max-width: 991px) {
  #logo img {
    width: 100px;
    height: auto; }
  .navigation {
    position: fixed;
    top: 0rem;
    right: 0rem;
    width: 100%;
    z-index: 1000;
    padding: 0px; }
  .nav {
    display: block;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.9);
    height: 100vh;
    max-height: 100vh;
    overflow-y: scroll;
    position: fixed;
    top: 0; }
  .nav-item {
    display: block;
    width: 90%;
    margin: 0 auto;
    padding: 0.5rem 0; }
  .nav-link {
    display: block;
    width: 100%;
    padding: 0.5rem 0; }
  .nav-link:hover {
    color: #229258; }
  .dropdown-menu {
    position: relative;
    top: 20px; }
  /*
* HOME
*/
  .headerimage--home .navigation {
    padding-top: 1rem; }
  .headerimage--default .nav-link {
    color: white; }
  .headerimage--default .nav-link:hover {
    color: #229258; }
  /*
* FOOTER
*/
  footer {
    text-align: center; }
    footer a {
      color: #ffff; }
    footer .footer-title {
      text-align: center; }
  .footer__bottom {
    border-bottom: 1px solid #333;
    border-top: 1px solid #333;
    width: 100%;
    display: block; }
  .footer__top .partner {
    margin-top: 1rem;
    margin-right: 0; }
    .footer__top .partner img {
      opacity: 0.3; }
  .footer__credits {
    text-align: center; }
    .footer__credits .madeby {
      float: center;
      display: block;
      margin-top: 2rem;
      text-align: center;
      width: 100%; } }

@media (max-width: 767px) {
  h1 {
    font-size: 2rem; }
  .headerimage--home {
    height: 60vh; }
    .headerimage--home .navigation {
      padding-top: 3rem; }
    .headerimage--home h1 {
      padding-top: 20vh; }
  .headerimage--default {
    height: 60vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    top: 0; }
    .headerimage--default #logo img {
      height: 60px;
      width: auto; }
    .headerimage--default .main-navigation {
      background-color: white; }
    .headerimage--default h1 {
      padding-top: 25vh; }
    .headerimage--default .nav-link {
      color: #2C4E46;
      text-transform: uppercase;
      font-weight: 700; }
    .headerimage--default .nav-link:hover {
      color: #229258; }
  .service-hover {
    background-color: #2C4E46;
    color: #229258;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-image: url("/img/Embleem_ARTofrunning_Green.svg");
    background-position: center;
    background-size: 50px 22.5px;
    background-repeat: no-repeat; }
    .service-hover p {
      position: absolute;
      bottom: 1rem;
      left: 0;
      width: 100%;
      text-align: center; } }

@media (max-width: 543px) {
  .dropdown-menu {
    position: relative;
    top: 20px; }
  .dropdown-item {
    margin: 1rem 0rem;
    display: block; } }

/*# sourceMappingURL=app.css.map */
